<template>
  <el-dialog
    append-to-body
    width="460px"
    title=""
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
  >
    <template #title>
      <div class="el-dialog-title">{{`${isChange ? '修改账号' : '新建账号'}`}}</div>
    </template>

    <el-form
      v-loading="loading"
      class="saft_form"
      element-loading-text="加载中…"
      ref="elFormDom"
      label-width="130px"
      label-position="top"
      :model="form.data"
      :rules="form.rules"
      size="small"
      hide-required-asterisk
    >
      <el-form-item prop="name" label="姓名">
        <el-input
          v-model="form.data.name"
          clearable
          placeholder="请输入姓名"
        ></el-input>
      </el-form-item>
      <el-form-item prop="account" label="账号">
        <el-input
          v-model="form.data.account"
          maxlength="11"
          clearable
          :readonly="isChange"
          placeholder="请输入账号"
        ></el-input>
      </el-form-item>
      <el-form-item prop="password" label="密码" v-if="!isChange">
        <el-input
          v-model="form.data.password"
          clearable
          placeholder="请输入密码"
        ></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button @click="toggle(false)">取消</el-button>
        <el-button
          type="primary"
          :loading="form.loading"
          :disabled="form.loading"
          @click="confirm"
          >确定</el-button
        >
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import common from '@/util'
  import { REG_EXP } from '@/util'
  export default {
    name: 'AccountEdit',
    data() {
      return {
        visible: false,
        isChange: false,
        loading: false,
        form: {
          loading: false,
          data: {
            name: '',
            account: '',
            password: '',
          },
          rules: {
            name: [{required: true, message: "请输入姓名！", trigger: "change"}],
            account: [
              {required: true, message: "请输入账号！", trigger: "change"},
              { pattern: REG_EXP.mobilePhone, message: "手机号不正确", trigger: 'blur' }
            ],
            password: [{required: true, message: "请输入密码！", trigger: "change"}],
          }
        },
      }
    },
    methods: {
      // 获取详情
      getDetail(row) {
        this.isChange = true
        common.deepClone(this.form.data, row)
      },
      reset(done) {
        this.isChange = false
        this.$refs.elFormDom.resetFields()
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 提交
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
            let apiUrl = !this.isChange ? '/government/account/add' : '/government/account/edit'
            this.form.loading = true;
            this.$http.post(apiUrl, this.form.data).then(res => {
              if(res.code === 1) {
                this.toggle(false)
                this.$emit('refresh')
              } else {
                this.$message.error(res.msg)
              }
           }).finally(() => {
             this.form.loading = false;
           })
          }
        })
      },
    }
  }
</script>
